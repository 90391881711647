<template>
  <div>
    <div id="map">
      <GmapMap
        ref="mapRef"
        :center="mapCenter"
        :map-type-id="mapType"
        :style="{width: '100%', height: mapHeight + 'px'}"
        :zoom="mapZoomLevel"
        class="map-height"
      >
        <GmapCluster
          :zoomOnClick="true"
          :position="mapCenter"
          :clickable="true"
          :minimumClusterSize="5"
          ref="cluster"
          :animation="2"
        >
          <GmapMarker
            v-for="(m, index) in markers"
            :key="index"
            :clickable="true"
            :draggable="false"
            :icon="m.icon"
            :position="m.position"
            @click="toggleInfoWindow(m,index)"
          />
          <GmapInfoWindow
            :opened="infoWinOpen"
            :options="infoOptions"
            :position="infoWindowPos"
            @closeclick="infoWinOpen=false"
          >
            <div v-html="infoContent"></div>
          </GmapInfoWindow>
        </GmapCluster>
      </GmapMap>
    </div>
    <div id="legend"></div>
  </div>
</template>

<script>
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues

export default {
  name: 'MapChart',
  components: { GmapCluster },
  props: {
    mapCenter: {
      type: Object,
      default: () => {
      },
      required: true
    },
    geoPositions: {
      type: Array,
      required: true
    },
    enabledMapLegends: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    mapHeight: {
      type: Number,
      default: () => {
        return 400
      }
    },
    mapZoomLevel: {
      type: Number,
      default: () => {
        return 3
      }
    }
  },
  data () {
    return {
      markers: [],
      places: [],
      currentPlace: null,
      map: null,
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoContent: '',
      infoWinOpen: false,
      currentMidx: null,
      // optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      mapType: 'roadmap'
    }
  },
  methods: {
    // receives a place object via the autocomplete component
    setPlace (place) {
      this.currentPlace = place
    },
    addMarker () {
      var features = this.geoPositions

      // Create markers.
      for (const feature in features) {
        this.markers.push({
          position: features[feature].position,
          icon: {
            url: require('@/assets/img/map-marker/' + this.enabledMapLegends[features[feature].type].icon),
            size: {
              width: 32,
              height: 30,
              f: 'px',
              b: 'px'
            },
            scaledSize: {
              width: 32,
              height: 30,
              f: 'px',
              b: 'px'
            }
          },
          name: features[feature].name,
          description: features[feature].description
        })
      }
      this.addLegends(this.enabledMapLegends)
    },
    addLegends (icons) {
      var legend = document.getElementById('legend')
      for (var key in icons) {
        var type = icons[key]
        var name = type.name
        // var icon = type.icon
        var div = document.createElement('div')
        div.innerHTML = '<span class="dot"></span> ' + name
        legend.appendChild(div)
      }
      this.$refs.mapRef.$mapPromise.then((map) => {
        // 9 - RIGHT_BOTTOM
        map.controls[8].push(legend)
      })
      document.getElementById('legend').remove()
    },
    toggleInfoWindow (marker, idx) {
      this.infoWindowPos = marker.position
      this.infoContent = this.getInfoWindowContent(marker)

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    getInfoWindowContent (marker) {
      return (`
<h3 class="popover-header">${marker.name}</h3>
<div class="popover-body">${marker.description}</div>
`)
    }
  },
  mounted () {
    this.addMarker()
  }
}
</script>

<style lang="scss" scoped>

</style>
